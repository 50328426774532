import { CodeExample } from 'components/CodeExamples'
import CallOut from 'components/Docs/CallOut'
import {
  DocH1,
  DocH2,
  DocH3,
  DocH4,
  DocH5,
  DocH6,
} from 'components/Docs/DocHeadings'
import DocImage from 'components/Docs/DocImage'
import DocLink from 'components/Docs/DocLink'
import { DocGrid, DocPanel } from 'components/Docs/DocLinksGrid'
import ExampleCodeBlock from 'components/Docs/ExampleCodeBlock'
import ExampleFlowBlock from 'components/Docs/ExampleFlowBlock'
import ExampleUIBlock from 'components/Docs/ExampleUIBlock'
import PreTagHighlighted from 'components/Docs/PreTagHighlighted'
import SubHeader from 'components/Docs/SubHeader'
import {
  WorkspaceRelatedLink,
  WorkspaceRelatedLinkProps,
} from 'components/Link/WorkspaceRelatedLink'

export function SimpleLink({
  path,
  children,
  ...props
}: Omit<WorkspaceRelatedLinkProps, 'to'> & { path: string }) {
  return (
    <WorkspaceRelatedLink to={path} {...props}>
      {children}
    </WorkspaceRelatedLink>
  )
}

function Blockquote({ children }) {
  return <CallOut type='info'>{children}</CallOut>
}

export const mdxProviderComponents = {
  DocGrid,
  DocPanel,
  DocLink,
  ExampleCodeBlock,
  ExampleFlowBlock,
  ExampleUIBlock,
  Link: SimpleLink,
  SubHeader,
  CodeExample,
  // Markdown elements
  blockquote: Blockquote,
  pre: PreTagHighlighted,
  img: DocImage,
  Image: DocImage,
  h1: DocH1,
  h2: DocH2,
  h3: DocH3,
  h4: DocH4,
  h5: DocH5,
  h6: DocH6,
}
