import { forwardRef, HTMLAttributes } from 'react'
import {
  SvgIcon,
  SvgIconType,
  Truncate,
  GenericTag,
  ITagProps,
} from '@integration-app/react'

import { Text } from 'ui-kit/text'
import clsx from 'utils/clsx'

import CopyToClipboard, {
  CopyToClipboardTooltip,
  useCopyToClipboard,
} from './CopyToClipboard'
import classes from './Tag.module.css'

interface ITagReadOnly extends HTMLAttributes<HTMLSpanElement> {
  copyToClipboard?: boolean
  icon?: SvgIconType
}

interface ITagEditable extends Omit<ITagReadOnly, 'copyToClipboard'> {
  children: string
  required?: boolean
  onChange: (arg) => void
}

type ITag = ITagEditable | ITagReadOnly

export default function Tag({ children, ...props }: ITag) {
  const { copied, copyToClipboard: copyToClipboardFn } =
    useCopyToClipboard(1500)

  if ((props as ITagEditable)?.onChange) {
    return (
      <TagEditable {...(props as ITagEditable)}>
        {children as string}
      </TagEditable>
    )
  }
  const { copyToClipboard, ...restProps } = props as ITagReadOnly

  if (copyToClipboard) {
    function handleOnClick(e: React.MouseEvent<HTMLDivElement>) {
      props?.onClick?.(e)
      void copyToClipboardFn(children as string)
    }

    return (
      <TagReadOnly
        icon={SvgIconType.Copy}
        {...restProps}
        onClick={handleOnClick}
      >
        {children}
        <Text as='span' position='absolute' right='0' top='50%'>
          <CopyToClipboardTooltip
            open={copied}
            positioning={{ placement: 'right', gutter: 8 }}
          />
        </Text>
      </TagReadOnly>
    )
  }
  return <TagReadOnly {...(props as ITagReadOnly)}>{children}</TagReadOnly>
}

const TagReadOnly = forwardRef<HTMLSpanElement, ITagReadOnly>(
  ({ children, className = '', icon, ...props }: ITagReadOnly, ref) => {
    return (
      <span className={clsx(classes.tag, className)} {...props} ref={ref}>
        {children}
        {icon && (
          <span className={clsx(classes.tagIcon)}>
            <SvgIcon type={icon} />
          </span>
        )}
      </span>
    )
  },
)

const TagEditable = forwardRef<HTMLSpanElement, ITagEditable>(
  (
    {
      children,
      className = '',
      required = false,
      // FIXME: strictNullCheck temporary fix
      // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type '(arg: ... Remove this comment to see the full error message
      onChange = undefined,
      ...props
    }: ITagEditable,
    ref,
  ) => {
    function handleChange(event) {
      if (typeof event?.target?.value === 'undefined') {
        return
      }
      onChange?.(event.target.value)
    }

    const isRequired = required && children === ''

    return (
      <TagReadOnly
        className={clsx(
          isRequired && classes.tagEditableRequired,
          classes.tagEditable,
          className,
        )}
        {...props}
        ref={ref}
      >
        {children}
        <input
          className={classes.input}
          onChange={handleChange}
          required={required}
          value={children}
        />
      </TagReadOnly>
    )
  },
)

export interface ITagId extends ITagProps {
  children: string
  icon?: SvgIconType
  iconAlways?: boolean
  truncate?: boolean
}

export const TagId = forwardRef<HTMLSpanElement, ITagId>(
  (
    { children, truncate, icon, iconAlways, className, ...props }: ITagId,
    ref,
  ) => {
    return (
      <GenericTag
        className={clsx(
          classes.tagId,
          props?.onClick && classes.tagIdClickable,
          className,
        )}
        ref={ref}
        {...props}
      >
        <span className={classes.tagIdValue}>
          <TagIdValue truncate={truncate}>{children}</TagIdValue>
          {icon && (
            <span
              className={clsx(
                classes.tagIdIcon,
                iconAlways && classes.tagIdIconAlways,
              )}
            >
              <SvgIcon type={icon} />
            </span>
          )}
        </span>
      </GenericTag>
    )
  },
)

export const TagIdCopyToClipboard = forwardRef<HTMLSpanElement, ITagId>(
  ({ children, ...props }: ITagId, ref) => {
    return (
      <CopyToClipboard value={children as string} timeout={1500}>
        <TagId icon={SvgIconType.Copy} {...props} ref={ref}>
          {children as string}
        </TagId>
      </CopyToClipboard>
    )
  },
)

function TagIdValue({
  children,
  truncate,
}: {
  children: string
  truncate?: boolean
}) {
  const str = children ?? ''

  if (truncate) {
    if (str.length <= 7) {
      return <>{str}</>
    }
    return (
      <>
        {'\u2026'}
        {'\uFEFF'}
        {str.slice(-7)}
      </>
    )
  }
  return <Truncate>{str}</Truncate>
}
