import { sva } from 'styled-system/css'
import { Box, BoxProps, HStack, HstackProps } from 'styled-system/jsx'
import { createSlotRecipeContext } from 'ui-kit/utils/create-slot-recipe-context'

const toolbarRecipe = sva({
  className: 'toolbar',
  slots: ['root', 'group', 'separator'],
  base: {
    root: {
      gap: 3,
    },
    group: {
      gap: 3,
      '& + &:before': {
        content: '""',
        display: 'block',
        height: '4',
        width: '1px',
        borderInlineStartWidth: 1,
        borderInlineStartColor: { _light: 'border.muted', _dark: 'gray.8' },
      },
    },
    separator: {
      display: 'block',
      height: '4',
      width: '1px',
      borderInlineStartWidth: 1,
      borderInlineStartColor: { _light: 'border.muted', _dark: 'gray.8' },
    },
  },
  variants: {
    variant: {
      floated: {
        root: {
          pointerEvents: 'auto',
          zIndex: 'docked',
          backgroundColor: {
            _light: 'bg.default',
            _dark: 'bg.default',
          },
          borderRadius: 'l3',
          paddingBlock: 2,
          paddingInline: 3,
          outlineColor: 'border.subtle',
          outlineWidth: '1',
          outlineStyle: 'solid',
          boxShadow: `
          0px -4px 8px {colors.gray.a2},
          -2px 0px 8px {colors.gray.a3},
          2px 0px 8px {colors.gray.a3},
          0px 0px 1px {colors.gray.a7}
          `,
          _hover: {
            outlineColor: 'border.muted',
            boxShadow: `
          0px -4px 10px {colors.gray.a3},
          -2px 0px 8px {colors.gray.a3},
          2px 0px 8px {colors.gray.a3},
          0px 0px 1px {colors.gray.a7}
          `,
          },
        },
      },
    },
  },
})

const context = createSlotRecipeContext(toolbarRecipe)

const Root = context.withProvider<HstackProps>(HStack, 'root')
const Group = context.withContext<HstackProps>(HStack, 'group')
const Separator = context.withContext<Omit<BoxProps, 'children'>>(
  Box,
  'separator',
)

const Toolbar = {
  Root,
  Group,
  Separator,
}

export { Toolbar }
