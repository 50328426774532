import { useRef } from 'react'

import { AnonymousLoginAndTryWrapper } from 'components/Docs/AnonymousLoginAndTryWrapper'
import { IntegrationAppProviderAsTestCustomer } from 'contexts/IntegrationAppProviderAsCustomer'
import { useRestoreDocScrollPosition } from 'hooks/useRestoreDocScrollPosition'
import { BackToIntegrationAppLogo } from 'routes/Docs/components/BackToIntegrationAppLogo'
import { DocsContextProvider } from 'routes/Docs/components/docs-context'
import { FirstLevelDocsMenu } from 'routes/Docs/components/DocsMenu/FirstLevelDocsMenu'
import { SecondLevelDocsMenu } from 'routes/Docs/components/DocsMenu/SecondLevelDocsMenu'
import { getArticle } from 'routes/Docs/helpers/articleList'
import { Flex } from 'styled-system/jsx'
import { getSlug } from 'utils/slug'

import { ArticleMdx } from './Article'
import { ArticleToc } from './ArticleToc'
import { BackToWorkspaceLink } from './BackToWorkspaceLink'
import { DocsHeaderBreadcrumbs } from './DocBreadcrumbsFromPath'
import {
  DocFullPageHeader,
  DocFullPageMain,
  DocFullPageSidebar,
  DocFullPageContent,
  DocFullPageArticle,
  DocFullPageToc,
} from './DocsLayout'
import { DocsSearchInput } from './DocsSearch'
import { ResourcesLinks } from './ResourcesLinks'

export default function RenderFullDoc({
  path,
  parameters = {},
}: {
  path: string
  parameters?: any
}) {
  const article = getArticle(path)

  const containerRef = useRef<Window>(window)
  useRestoreDocScrollPosition(containerRef, path)

  const contentContainerId = `doc-page-${getSlug(path)}`

  return (
    <IntegrationAppProviderAsTestCustomer>
      <DocFullPageHeader>
        <BackToIntegrationAppLogo />
        <FirstLevelDocsMenu />
        <Flex
          gridArea='links'
          alignSelf={{
            base: 'center',
            md: 'start',
            xl: 'center',
          }}
          justifySelf='start'
          direction={{
            base: 'row-reverse',
            sm: 'row',
          }}
          gap='2'
          alignItems='center'
        >
          <ResourcesLinks />
          <DocsSearchInput />
        </Flex>
      </DocFullPageHeader>
      <DocFullPageMain>
        <DocFullPageSidebar>
          <BackToWorkspaceLink />
          <SecondLevelDocsMenu />
        </DocFullPageSidebar>
        <DocFullPageContent>
          <DocFullPageArticle id={contentContainerId}>
            <DocsHeaderBreadcrumbs path={path} />
            <DocsContextProvider parameters={parameters}>
              <AnonymousLoginAndTryWrapper>
                <ArticleMdx article={article} parameters={parameters} />
              </AnonymousLoginAndTryWrapper>
            </DocsContextProvider>
          </DocFullPageArticle>
          <DocFullPageToc>
            <ArticleToc
              article={article}
              articleContainerId={contentContainerId}
              scrollableContainerSelector={'window'}
            />
          </DocFullPageToc>
        </DocFullPageContent>
      </DocFullPageMain>
    </IntegrationAppProviderAsTestCustomer>
  )
}
