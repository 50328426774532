import { ReactElement } from 'react'
import { SvgIcon, SvgIconType } from '@integration-app/react'
import { FlowNode } from '@integration-app/sdk'
import { toHeaderCase, toKebabCase, toSentenceCase } from 'js-convert-case'

import { ItemButton } from 'components/Popup/components/ItemButton'
import { HStack } from 'styled-system/jsx'
import { Badge } from 'ui-kit/badge'

import { useAddNodeContext } from './add-node-context'
import { CONSOLE_NODE_SPECS } from '../../../SidePanels/nodes'

export function NodeItem({
  node,
  showType = false,
  showDataSource = false,
  showDataCollection = false,
  showUDM = false,
}: {
  node: FlowNode
  showType?: boolean
  showDataSource?: boolean
  showDataCollection?: boolean
  showUDM?: boolean
}) {
  const { onSelectNode } = useAddNodeContext()

  const { name, type, config } = node

  const icon = type ? CONSOLE_NODE_SPECS[type]?.icon : undefined
  const dataSourceKey = config?.dataSource?.key
  const collectionKey = config?.dataSource?.collectionKey
  const udmKey = config?.dataSource?.udm

  const isTypeVisible = showType && type !== toKebabCase(name)

  const iconComponent = icon ? (
    <ItemButton.Icon>
      <IconComponent icon={icon} name={name} />
    </ItemButton.Icon>
  ) : (
    <ItemButton.Icon>
      <SvgIcon type={SvgIconType.QuestionMark} />
    </ItemButton.Icon>
  )

  return (
    <ItemButton.Root
      onClick={() => onSelectNode(node)}
      leftSlot={iconComponent}
      rightSlot={
        <HStack>
          {showDataSource && !!dataSourceKey && (
            <NodeProp>Data Source: {toSentenceCase(dataSourceKey)}</NodeProp>
          )}
          {showDataCollection && !!collectionKey && (
            <NodeProp>
              Data Collection: {toSentenceCase(collectionKey)}
            </NodeProp>
          )}
          {showUDM && !!udmKey && (
            <NodeProp>UDM: {toSentenceCase(config?.dataSource?.udm)}</NodeProp>
          )}
          {isTypeVisible && <NodeProp>Type: {toHeaderCase(type)}</NodeProp>}
        </HStack>
      }
    >
      <ItemButton.Header>{name}</ItemButton.Header>
    </ItemButton.Root>
  )
}

export function IconComponent({
  icon,
  name,
}: {
  icon: string | ReactElement
  name?: string
}) {
  if (typeof icon === 'string') {
    return <img src={`data:image/svg+xml;base64,${btoa(icon)}`} alt={name} />
  }
  return icon
}

export function EmptyNodeItem({ name }: { name?: string }) {
  const { onSelectNode } = useAddNodeContext()

  name = name ? name : 'Empty Node'

  return (
    <ItemButton.Root
      onClick={() => onSelectNode({ name, type: undefined })}
      leftSlot={
        <ItemButton.Icon>
          <SvgIcon type={SvgIconType.QuestionMark} />
        </ItemButton.Icon>
      }
    >
      <ItemButton.Header>
        <div className={'flex justify-between items-center'}>
          <span>{name}</span>
        </div>
      </ItemButton.Header>

      <ItemButton.Description>
        Create an empty node and configure it later
      </ItemButton.Description>
    </ItemButton.Root>
  )
}

function NodeProp({ children }: { children: React.ReactNode }) {
  return (
    <Badge variant='outline' color='fg.muted' size='sm'>
      {children}
    </Badge>
  )
}
