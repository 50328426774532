import { UNIFIED_DATA_MODELS, schemaWithTitle } from '@integration-app/sdk'
import { toHeaderCase } from 'js-convert-case'
import { TbArrowUpRight } from 'react-icons/tb'

import { ConfigCard } from 'components/Card/ConfigCard'
import DocLink from 'components/Docs/DocLink'
import { RadioGroup } from 'components/RadioGroup'
import { Code } from 'ui-kit/code'
import { Text } from 'ui-kit/text'

import { CustomImportFieldMapping } from './CustomImportFieldMapping'
import { IncludeRawFieldsBlock } from './IncludeRawFieldsBlock'
import { SharedImportFieldMapping } from './SharedImportFieldMapping'
import { useGenericConfig } from '../contexts/generic-config-context'

enum ImportFieldMappingUnitConfigOption {
  None = 'none',
  Udm = 'udm',
  Custom = 'custom',
  Shared = 'shared',
}

export function UniversalImportFieldMappingUnitConfig() {
  const { patchConfig, patchFieldMappingConfig, fieldMappingConfig, udm } =
    useGenericConfig()

  async function handleSelectOption({
    value,
  }: {
    value: ImportFieldMappingUnitConfigOption
  }) {
    switch (value) {
      case ImportFieldMappingUnitConfigOption.None:
        return patchConfig({
          fieldMapping: undefined,
        })
      case ImportFieldMappingUnitConfigOption.Udm:
        return patchConfig({
          fieldMapping: {
            defaultUnifiedValue: {
              $var: '$',
            },
          },
        })
      case ImportFieldMappingUnitConfigOption.Custom:
        let newValue =
          fieldMappingConfig.defaultUnifiedValue ??
          fieldMappingConfig.default ??
          {}
        if (newValue.$var === '$') {
          newValue = {}
        }
        return patchConfig({
          fieldMapping: {
            defaultUnifiedValue: newValue,
          },
        })
      case ImportFieldMappingUnitConfigOption.Shared:
        return patchConfig({
          fieldMapping: {
            key: null,
          },
        })
    }
  }

  const externalFieldsSchema = udm
    ? schemaWithTitle(
        UNIFIED_DATA_MODELS[udm]?.fieldsSchema,
        'Universal Fields',
      )
    : undefined

  const fields =
    fieldMappingConfig?.defaultUnifiedValue ?? fieldMappingConfig?.default // fallback to `default` for backward compatibility

  const fieldsSchema = {
    type: 'object',
    additionalProperties: true,
  }

  let selectedOption = ImportFieldMappingUnitConfigOption.None

  if (fields !== undefined) {
    if (fields.$var === '$') {
      selectedOption = ImportFieldMappingUnitConfigOption.Udm
    } else {
      selectedOption = ImportFieldMappingUnitConfigOption.Custom
    }
  }

  if (fieldMappingConfig?.key !== undefined) {
    selectedOption = ImportFieldMappingUnitConfigOption.Shared
  }

  return (
    <ConfigCard.Root>
      <ConfigCard.Header>
        <ConfigCard.Title>Field Mapping</ConfigCard.Title>
      </ConfigCard.Header>
      <ConfigCard.Content display={'flex'} flexDirection={'column'} gap={4}>
        <Text m={0}>
          Configure the contents of the <Code>fields</Code> property of data
          records returned by this action.
        </Text>

        <RadioGroup.Root
          gap={1}
          value={selectedOption}
          onValueChange={handleSelectOption}
        >
          {udm && UNIFIED_DATA_MODELS[udm] && (
            <RadioGroup.Item value={ImportFieldMappingUnitConfigOption.Udm}>
              Map to a standard fields schema for{' '}
              {toHeaderCase(UNIFIED_DATA_MODELS[udm]?.pluralName)}.
              <DocLink
                path={`membrane/references/udm#${udm}`}
                underlined
                marginInlineStart={4}
                rightIcon={<TbArrowUpRight />}
              >
                See schema
              </DocLink>
            </RadioGroup.Item>
          )}
          <RadioGroup.Item value={ImportFieldMappingUnitConfigOption.Custom}>
            Map to a custom schema
          </RadioGroup.Item>
          <RadioGroup.Item value={ImportFieldMappingUnitConfigOption.Shared}>
            Use shared field mapping config
          </RadioGroup.Item>
          <RadioGroup.Item value={ImportFieldMappingUnitConfigOption.None}>
            Keep original fields returned from a connector
          </RadioGroup.Item>
        </RadioGroup.Root>

        {selectedOption === ImportFieldMappingUnitConfigOption.Shared && (
          <SharedImportFieldMapping />
        )}

        {selectedOption === ImportFieldMappingUnitConfigOption.Custom && (
          <CustomImportFieldMapping
            value={
              fieldMappingConfig?.defaultUnifiedValue ??
              fieldMappingConfig?.default
            }
            onChange={(defaultUnifiedValue) =>
              patchFieldMappingConfig({ defaultUnifiedValue })
            }
            internalFieldsSchema={fieldsSchema}
            externalFieldsSchema={externalFieldsSchema}
          />
        )}

        {selectedOption !== ImportFieldMappingUnitConfigOption.None && (
          <IncludeRawFieldsBlock />
        )}
      </ConfigCard.Content>
    </ConfigCard.Root>
  )
}
