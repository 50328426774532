import useAuth from 'contexts/auth'
import { sva } from 'styled-system/css'
import { VStack } from 'styled-system/jsx'
import { Button } from 'ui-kit/button'
import { Text } from 'ui-kit/text'

export function AnonymousLoginAndTryWrapper({ children }) {
  const { self } = useAuth()
  const isLoggedIn = !!self?.user?.id

  if (isLoggedIn) {
    return children
  }
  return (
    <>
      {children}
      <LoginAndTryFooter />
    </>
  )
}

const recipe = sva({
  slots: ['root', 'button'],
  base: {
    root: {
      alignItems: 'flex-start',
      gap: '3',
      padding: '5',
      maxWidth: '5xl',
      marginBlockStart: '16',
      borderColor: 'primary.a8',
      borderWidth: '1px',
      borderRadius: 'l2',
      position: 'relative',
      _hover: {
        color: 'primary.default',
        borderColor: 'primary.emphasized',
      },
    },
    button: {
      position: 'static',
      _after: {
        content: '""',
        position: 'absolute',
        inset: 0,
      },
    },
  },
})

const recipeClassnames = recipe()

function LoginAndTryFooter() {
  const { login } = useAuth()
  return (
    <VStack className={recipeClassnames.root}>
      <Text textStyle='xl' m='0'>
        Integration.app is free to try. It takes just one click to get started.
      </Text>
      <Button
        className={recipeClassnames.button}
        variant='solid'
        size='dense'
        colorPalette='primary'
        onClick={() => login()}
      >
        Login and Try it Yourself
      </Button>
    </VStack>
  )
}
