import {
  getSchemaByLocator,
  getValueAtLocator,
  getVariableLocators,
} from '@integration-app/sdk'

import { ConfigurationErrorType, NodeConfigurationErrorData } from './types'

export function getBrokenVariablesConfigurationErrors(
  value: any,

  schemas: any[],
): NodeConfigurationErrorData[] {
  const valueLocators = getVariableLocators(value).filter(
    (valueLocator) => valueLocator !== '$', // Exclude root locator because it's always valid
  )

  const valueLocatorsWithVariables = valueLocators.map((valueLocator) => ({
    varFormula: getValueAtLocator(value, valueLocator)?.$var,
    valueLocator,
  }))

  const errors = valueLocatorsWithVariables
    .map(({ varFormula, valueLocator }) => {
      const varFormulaResolved = schemas.some(
        (s) => !!getSchemaByLocator(s, varFormula),
      )

      return varFormulaResolved
        ? null
        : {
            type: ConfigurationErrorType.BrokenVariable,
            message: 'Broken variable.',
            valueLocator,
          }
    })
    .filter(Boolean) as NodeConfigurationErrorData[]

  return errors
}
