import { sva } from 'styled-system/css'
import { Box, Square, SquareProps } from 'styled-system/jsx'
import * as StyledSwitch from 'ui-kit/styled/switch'
import { SwitchProps } from 'ui-kit/switch'

import { ChildrenOrTooltipProps, SwitchControl } from './index'

const onOffSwitchRecipe = sva({
  className: 'onOffSwitch',
  slots: ['thumb', 'labelLeft', 'labelRight'],
  base: {
    thumb: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr',
      _checked: {
        '& .onOffSwitch__labelLeft': {
          visibility: 'hidden',
        },
        '& .onOffSwitch__labelRight': {
          visibility: 'visible',
        },
      },
    },
    labelLeft: {
      gridArea: '1/1',
      width: '4',
      height: '4',
    },
    labelRight: {
      gridArea: '1/1',
      width: '4',
      height: '4',
      visibility: 'hidden',
    },
  },
})

export type OnOffSwitchProps = Omit<SwitchProps, 'children'> &
  ChildrenOrTooltipProps

export function OnOffSwitch({
  children,
  tooltip,
  ...rootProps
}: OnOffSwitchProps) {
  const classes = onOffSwitchRecipe()
  return (
    <StyledSwitch.Root {...rootProps}>
      <SwitchControl position='relative' tooltip={tooltip}>
        <StyledSwitch.Thumb className={classes.thumb}>
          <TextLabel className={classes.labelLeft}>on</TextLabel>
          <TextLabel className={classes.labelRight}>off</TextLabel>
        </StyledSwitch.Thumb>
      </SwitchControl>
      {children && <StyledSwitch.Label>{children}</StyledSwitch.Label>}
      <StyledSwitch.HiddenInput />
    </StyledSwitch.Root>
  )
}

// Browsers have a minimum font size of 8-10-12px for accessibility reasons.
// We need to scale the text down to 6px to fit the switch.
function TextLabel({ children, ...props }: SquareProps) {
  return (
    <Square
      flexShrink='0'
      fontSize='2xl'
      fontWeight='medium'
      lineHeight='none'
      {...props}
    >
      <Box transform='scale(0.48)'>{children}</Box>
    </Square>
  )
}
