import { PropsWithChildren } from 'react'

import { Box } from 'styled-system/jsx'
import clsx from 'utils/clsx'

import classes from './styles.module.css'

export function DocFullPageMain({ children }: PropsWithChildren) {
  return (
    <Box
      backgroundColor='bg.canvas'
      className={clsx(classes.site_main, classes.container)}
    >
      {children}
    </Box>
  )
}
