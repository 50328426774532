import { createContext, ReactNode, useContext } from 'react'

type AddNodeContextType = {
  isTriggers?: boolean
  showUdmNodes?: boolean
  allowEmptyNode?: boolean
  integrationId?: string
  onSelectNode: (node: any) => void
  onStartLinking?: () => void
}

const AddNodeContext = createContext<AddNodeContextType | undefined>(undefined)

export function AddNodeContextProvider({
  children,
  allowEmptyNode = true,
  showUdmNodes = true,
  isTriggers = false,
  integrationId,
  onSelectNode,
  onStartLinking,
}: {
  children: ReactNode
  allowEmptyNode?: boolean
  showUdmNodes?: boolean
  isTriggers?: boolean
  integrationId?: string
  onSelectNode: (node: any) => void
  onStartLinking?: () => void
}) {
  return (
    <AddNodeContext.Provider
      value={{
        isTriggers,
        integrationId,
        onSelectNode,
        onStartLinking,
        allowEmptyNode,
        showUdmNodes,
      }}
    >
      {children}
    </AddNodeContext.Provider>
  )
}

export const useAddNodeContext = () => {
  const context = useContext(AddNodeContext)
  if (!context) {
    throw new Error(
      'useAddNodeContext must be used within a AddNodeContextProvider',
    )
  }
  return context
}
