import { ReactNode, useState } from 'react'
import { DataSchema } from '@integration-app/sdk'
import { SchemaBuilder, SchemaBuilderProps } from '@integration-app/ui'
import { TbCode } from 'react-icons/tb'

import { ConfigCard } from 'components/Card/ConfigCard'
import { ContentRows } from 'components/ContentRows'
import YamlEditor from 'components/Editors/YamlEditor'
import { Switch } from 'components/Switch'
import { HStack } from 'styled-system/jsx'

interface SchemaConfigCardProps {
  title: string
  schema: DataSchema
  onChange?: (schema: DataSchema) => void
  readOnly?: boolean
  leftSlot?: ReactNode
  rightSlot?: ReactNode
  variablesSchema?: DataSchema
  schemaBuilderProps?: Partial<SchemaBuilderProps>
}

export function SchemaConfigCard({
  title,
  schema,
  onChange,
  readOnly = false,
  leftSlot,
  rightSlot,
  variablesSchema,
  schemaBuilderProps,
}: SchemaConfigCardProps) {
  const [codeMode, setCodeMode] = useState(false)

  return (
    <ConfigCard.Root>
      <ConfigCard.Header
        leftSlot={leftSlot}
        menuSlot={
          <HStack gap={4}>
            {rightSlot}
            {!readOnly && (
              <Switch
                tooltip='Toggle Code Mode'
                iconOn={<TbCode />}
                iconOff={<TbCode />}
                checked={codeMode}
                onCheckedChange={() => setCodeMode(!codeMode)}
              />
            )}
          </HStack>
        }
      >
        <ConfigCard.Title>{title}</ConfigCard.Title>
      </ConfigCard.Header>
      <ConfigCard.Content>
        <ContentRows>
          {codeMode ? (
            <YamlEditor
              readOnly={readOnly}
              value={schema}
              onChange={onChange}
              scrollable={false}
            />
          ) : (
            <SchemaBuilder
              schema={schema}
              onChange={onChange}
              readOnly={readOnly}
              variablesSchema={variablesSchema}
              {...schemaBuilderProps}
            />
          )}
        </ContentRows>
      </ConfigCard.Content>
    </ConfigCard.Root>
  )
}
