import React, { forwardRef, useState } from 'react'
import { SvgIconType } from '@integration-app/react'
import useEventStopPropagation from '@integration-app/ui/hooks/useEventStopPropagation'
import { useRouter } from 'next/router'

import { Menu } from 'components/Menu'

import { useCopyToClipboard, CopyToClipboardTooltip } from './CopyToClipboard'
import { ITagId, TagId, TagIdCopyToClipboard } from './Tag'

interface ITableCellTagRoot extends ITagId {
  children: string
  copyToClipboard?: boolean
}

const TableCellTagRoot = forwardRef<HTMLSpanElement, ITableCellTagRoot>(
  ({ children, title, copyToClipboard = true, ...props }, ref) => {
    const Component = copyToClipboard ? TagIdCopyToClipboard : TagId
    return (
      <Component title={title || (children as string)} {...props} ref={ref}>
        {children}
      </Component>
    )
  },
)
TableCellTagRoot.displayName = 'TableCellTag'

function NameWithDropdown<Item extends { id: string; name?: string }>({
  item,
  truncate = false,
  filterByValue,
  filterByField,
  onDetailsClick,
}: {
  item: Item
  truncate?: boolean
  filterByField?: string
  filterByValue?: string
  onDetailsClick?: () => void
}) {
  const [open, setOpen] = useState(false)
  const { id, name } = item

  const router = useRouter()
  const { copied, copyToClipboard } = useCopyToClipboard()

  function handleSelect({ value }) {
    switch (value) {
      case 'copy':
        void copyToClipboard(id)
        break
      case 'details':
        onDetailsClick?.()
        break
      case 'filterBy':
        void router.push({
          query: {
            ...router.query,
            ...(filterByField ? { [filterByField]: filterByValue } : {}),
          },
        })
        break
      default:
        break
    }
  }

  function toggleMenu() {
    setOpen((open) => !open)
  }

  return (
    <>
      <Menu.Root open={open} onOpenChange={toggleMenu} onSelect={handleSelect}>
        <Menu.Trigger
          _focusVisible={{
            colorPalette: 'gray',
            outline: '2px solid',
            outlineColor: 'colorPalette.default',
            outlineOffset: '2px',
            borderRadius: 'l2',
          }}
          asChild
        >
          <TagId
            icon={SvgIconType.ChevronDown}
            title={name || id}
            truncate={truncate}
            onClick={toggleMenu}
          >
            {name || id}
          </TagId>
        </Menu.Trigger>
        <Menu.Content usePortal onClick={useEventStopPropagation(true)}>
          {id && <Menu.Item value='copy'>Copy</Menu.Item>}
          {onDetailsClick && <Menu.Item value='details'>Details</Menu.Item>}
          {filterByField && <Menu.Item value='filterBy'>Filter By</Menu.Item>}
        </Menu.Content>
      </Menu.Root>
      <CopyToClipboardTooltip open={copied} />
    </>
  )
}

const TableCellTag = Object.assign(TableCellTagRoot, {
  NameWithDropdown,
})
export default TableCellTag
