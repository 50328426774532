import { useRef } from 'react'
import Editor, { EditorProps, Monaco } from '@monaco-editor/react'

import { HStackLoader } from 'components/Loader'
import { Box } from 'styled-system/jsx'

export default function TextEditor({
  scrollable = true,
  loading = false,
  ...props
}: {
  scrollable?: boolean
} & EditorProps) {
  const monacoRef = useRef(null)
  const theme = 'integrationAppTheme'
  const containerRef = useRef<HTMLDivElement>(null)

  function handleEditorWillMount(monaco: Monaco) {
    props.beforeMount?.(monaco)
    monaco.editor.defineTheme(theme, {
      base: 'vs',
      inherit: true,
      rules: [],
      colors: {},
    })
  }

  function handleEditorDidMount(editor) {
    editor.getModel().updateOptions({ tabSize: 2 })
    monacoRef.current = editor

    if (scrollable) {
      return
    }

    const updateHeight = () => {
      const contentHeight = editor.getContentHeight()
      const parentWidth = containerRef?.current?.offsetWidth
      if (containerRef.current) {
        containerRef.current.style.height = `${contentHeight}px`
        try {
          editor.layout({ width: parentWidth, height: contentHeight })
        } catch {}
      }
    }

    editor.onDidContentSizeChange(updateHeight)
    updateHeight()
  }

  return (
    <Box
      ref={containerRef}
      width='full'
      height='full'
      minHeight={150}
      display='grid'
      placeItems='stretch'
    >
      {loading && <HStackLoader placeSelf='center'>Loading</HStackLoader>}
      {!loading && (
        <Editor
          {...props}
          width='100%'
          height='100%'
          theme={theme}
          beforeMount={handleEditorWillMount}
          onMount={handleEditorDidMount}
          options={{
            minimap: {
              enabled: false,
            },
            glyphMargin: false,
            lineDecorationsWidth: 0,
            // folding: false,
            // lineNumbers: 'off',
            lineNumbersMinChars: 1,
            ...(props.options ?? {}),
            ...(!scrollable && {
              scrollbar: {
                useShadows: false,
                vertical: 'hidden',
                alwaysConsumeMouseWheel: false,
              },
              scrollBeyondLastLine: false,
              wrappingStrategy: 'advanced',
              overviewRulerLanes: 0,
              wordWrap: 'off',
            }),
          }}
        />
      )}
    </Box>
  )
}
