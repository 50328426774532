import { getArticleBreadcrumbs } from 'routes/Docs/helpers/articleList'

import { DocBreadcrumbs } from './DocsBreadcrumbs'

export default function DocBreadcrumbsFromPath({ path }: { path: string }) {
  if (path === 'overview') {
    return null
  }

  const breadcrumbs = getArticleBreadcrumbs(path)

  return <DocBreadcrumbs breadcrumbs={breadcrumbs} />
}

export function DocsHeaderBreadcrumbs({ path }: { path: string }) {
  const breadcrumbs = [
    {
      title: 'Docs',
      path: '/',
    },
    ...getArticleBreadcrumbs(path),
  ]

  return <DocBreadcrumbs breadcrumbs={breadcrumbs} />
}
