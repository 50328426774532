import { useRouter } from 'next/router'
import { useLocation } from 'react-router-dom'

import { getDocMenu } from 'routes/Docs/components/DocsMenu/helpers'
import {
  SDK_REFERENCE,
  REACT_REFERENCE,
  REST_API_REFERENCE,
} from 'routes/Docs/constants'

export function useDocMenu() {
  const { pathname } = useLocation()
  const { route } = useRouter()
  const isDocInWorkspace = route.startsWith('/w/[workspaceId]')

  const clearPathname = pathname
    .split('/')
    .slice(isDocInWorkspace ? 2 : 1) // Remove leading slash or `/docs/`
    .join('/')

  const docMenu = getDocMenu(clearPathname)

  // This is a pointer to menu item
  const engineReferencesMenuPointer = docMenu.find(
    (item) => (item as any).title === 'References',
  )

  engineReferencesMenuPointer?.links?.push(
    ...[
      {
        to: SDK_REFERENCE,
        title: 'SDK',
        isExternalLink: true,
      },
      {
        to: REACT_REFERENCE,
        title: 'React Library',
        isExternalLink: true,
      },
      {
        to: REST_API_REFERENCE,
        title: 'REST API',
        isExternalLink: true,
      },
    ],
  )

  return docMenu
}
