import { TbArrowBackUp } from 'react-icons/tb'

import useAuth from 'contexts/auth'
import { SimpleLink } from 'routes/Docs/helpers/markdown-components'
import { Icon } from 'ui-kit/icon'

import classes from './BackToWorkspaceLink.module.css'

export function BackToWorkspaceLink() {
  const { self } = useAuth()

  if (!self?.user?.id) return null

  return (
    <div className={classes.wrapper}>
      <SimpleLink path='/' className={classes.link}>
        <Icon size='sm'>
          <TbArrowBackUp />
        </Icon>
        Back to Workspace
      </SimpleLink>
    </div>
  )
}
