import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useRouter } from 'next/router'
import { useThrottledCallback } from 'use-debounce'

import { useScroll } from 'hooks/useScroll'

export function useRestoreDocScrollPosition(
  ref: MutableRefObject<HTMLElement | Window>,
  path: string,
) {
  const scrollPositionMap = useRef(new Map<string, number>())
  const [historyStateNavigation, cleanHistoryStateNavigation] =
    useOnHistoryStateNavigationState()
  const routeChanging = useRouterNavigation()

  const handleScroll = useCallback(
    ({ scrollY }) => {
      if (!routeChanging) {
        scrollPositionMap.current.set(path, scrollY)
      }
    },
    [path, routeChanging],
  )

  const handleScrollThrottled = useThrottledCallback(handleScroll, 100, {
    trailing: false,
  })

  // Save scroll position for each path
  useScroll(ref?.current, handleScrollThrottled)

  // When user navigates the session history, scroll to the previous position
  // Otherwise, scroll to the hash position
  // Otherwise, scroll to the top of the element
  useEffect(() => {
    let scrollToY = 0
    const hash = getHash()
    if (historyStateNavigation) {
      cleanHistoryStateNavigation()
      scrollToY = scrollPositionMap.current.get(path) ?? scrollToY
    } else if (hash) {
      const element = document.getElementById(hash.split('#')[1])
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
        return
      }

      const hashOffsetTop = getHashOffsetTop(hash, ref)
      scrollToY = hashOffsetTop ?? scrollToY
    }
    ref?.current?.scrollTo(0, scrollToY)
  }, [path])
}

function getHash() {
  if (typeof window !== 'undefined') {
    return window?.location?.hash ?? null
  }

  return null
}

function getHashOffsetTop(
  hash: string,
  ref: MutableRefObject<HTMLElement | Window>,
) {
  // id values starts with digits are perfectly valid in HTML
  // but querySelector does not work with them directly
  // so we need to escape them
  const encodedHash = hash.replace(/\d/g, function (match: string) {
    return '\\' + match.charCodeAt(0).toString(16).padStart(2, '0')
  })

  const el =
    ref?.current === window ? window.document : (ref?.current as HTMLElement)

  return (el?.querySelector(encodedHash) as HTMLElement)?.offsetTop ?? 0
}

function useOnHistoryStateNavigationState(): [boolean, () => void] {
  const [historyNavigation, setHistoryNavigation] = useState(false)

  const cb = () => {
    setHistoryNavigation(true)
  }
  const clean = useCallback(() => {
    setHistoryNavigation(false)
  }, [])

  useEffect(() => {
    // Detect when user navigates the session history
    window.addEventListener('popstate', cb)
    return () => {
      window.removeEventListener('popstate', cb)
    }
  }, [cb])

  return [historyNavigation, clean]
}

function useRouterNavigation() {
  const [routeChanging, setRouteChanging] = useState(false)
  const router = useRouter()

  useEffect(() => {
    // subscribe to routeChangeStart event
    const start = () => {
      setRouteChanging(true)
    }
    const stop = () => {
      setRouteChanging(false)
    }
    router.events.on('routeChangeStart', start)
    router.events.on('routeChangeComplete', stop)

    // unsubscribe on component destroy in useEffect return function
    return () => {
      router.events.off('routeChangeStart', start)
      router.events.off('routeChangeComplete', stop)
    }
  }, [])

  return routeChanging
}
