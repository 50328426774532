import {
  DataCollectionSpec,
  DataSchema,
  DataSource,
  FieldMapping,
  Flow,
  FlowNode,
  UnifiedDataModel,
} from '@integration-app/sdk'

export enum ConfigurationErrorType {
  BrokenVariable = 'BrokenVariable',
  MissingFieldValue = 'MissingFieldValue',
  BrokenLinkToNode = 'BrokenLinkToNode',
  MissingType = 'MissingType',
}

export type NodeConfigurationErrorData = {
  type: ConfigurationErrorType
  message: string
  valueLocator?: string

  data?: any
}

export type NodeConfigurationErrorsGetterArgs = {
  flow: Flow
  nodeKey: string
  node: FlowNode

  config: any

  fieldMapping?: FieldMapping

  dataSource?: DataSource

  defaultPath?: string
  dataCollectionSpec?: DataCollectionSpec

  udm?: string
  udmSpec?: UnifiedDataModel

  runTimeVariablesSchema: DataSchema
  inputSchema: DataSchema
}

export type NodeConfigurationErrorsGetter = (
  args: NodeConfigurationErrorsGetterArgs,
) => NodeConfigurationErrorData[]
