import { forwardRef, HTMLAttributes } from 'react'
import { SvgIcon, SvgIconType } from '@integration-app/react'

import clsx from 'utils/clsx'

import classes from './NodeBody.module.css'

type CommonRoundedButtonProps = HTMLAttributes<HTMLButtonElement> & {
  iconType: SvgIconType
  squared?: boolean
}

export const CommonRoundedButton = forwardRef<
  HTMLButtonElement,
  CommonRoundedButtonProps
>(({ iconType, className, squared = false, ...props }, ref) => {
  return (
    <button
      ref={ref}
      className={clsx(
        className,
        classes.commonRoundedButton,
        squared && classes.commonRoundedButtonSquared,
      )}
      {...props}
    >
      <SvgIcon type={iconType} />
    </button>
  )
})
