import { PropsWithChildren } from 'react'
import { useIntegration } from '@integration-app/react'

import { Popup } from 'components/Popup'

export function CreateDataSourcePopupTitle({
  children,
  integrationId,
  onBack,
}: PropsWithChildren<{
  integrationId?: string
  onBack?(): void
}>) {
  const leftSlot = onBack ? (
    <Popup.Title.BackButton
      tooltip={'Back to select data model'}
      onClick={onBack}
    >
      Back
    </Popup.Title.BackButton>
  ) : null

  return (
    <Popup.Title leftSlot={leftSlot}>
      {children || <CreateDataSourceTitle integrationId={integrationId} />}
    </Popup.Title>
  )
}

function CreateDataSourceTitle({ integrationId }: { integrationId?: string }) {
  const { integration } = useIntegration(integrationId)
  if (!integrationId) {
    return <>Create Universal Data Source</>
  }

  if (!integration?.name) {
    return <>Create Data Source</>
  }

  return <>Create Data Source for {integration?.name}</>
}
