import { SvgIcon, SvgIconType } from '@integration-app/react'
import { useRouter } from 'next/router'

import { Link as NavLink } from 'components/Link'
import { css } from 'styled-system/css'
import { Box } from 'styled-system/jsx'
import { Link } from 'ui-kit/link'

const classes = css({
  gridArea: 'logo',
  display: 'inline-flex',
  gap: 2,
  fontWeight: 'medium',
  alignSelf: 'center',
})

export function BackToIntegrationAppLogo() {
  const { route } = useRouter()

  const isDocInWorkspace = route.startsWith('/w/[workspaceId]')

  if (!isDocInWorkspace)
    return (
      <Link href={'https://integration.app'} className={classes}>
        <Title />
      </Link>
    )

  return (
    <NavLink to={'/'} className={classes}>
      <Title />
    </NavLink>
  )
}

function Title() {
  return (
    <>
      <SvgIcon className={'w-8 h-5'} type={SvgIconType.AppIcon} />{' '}
      Integration.app
      <Box
        display={{
          base: 'none',
          xl: 'block',
        }}
        height='4'
        width='1px'
        marginInlineStart='2'
        marginInlineEnd='1'
        borderInlineStartWidth='1'
        borderInlineStartColor='border.muted'
      ></Box>
    </>
  )
}
