import React from 'react'
import { useBoolean } from 'usehooks-ts'

import { ConfigCard } from 'components/Card/ConfigCard'
import YAMLEditor from 'components/Editors/YamlEditor'
import { Toolbar } from 'components/Toolbar'
import { Box, BoxProps, Grid } from 'styled-system/jsx'
import { ComponentProps } from 'styled-system/types'

export type ToolbarComponentProps = {
  codeMode: boolean
  onToggle: () => void
} & ComponentProps<typeof Toolbar.Root>

export function ConfigCardWrapperWithToolbarCodeModeSwitch({
  children,
  onCodeChange,
  code,
  toolbar: ToolbarComponent,
  readOnlyCode,
  ...props
}: BoxProps & {
  code: any
  onCodeChange(data: string): void
  readOnlyCode?: boolean
  toolbar: React.ComponentType<ToolbarComponentProps>
}) {
  const { value: codeMode, toggle: toggleCodeMode } = useBoolean(false)

  return (
    <Grid
      gridTemplateAreas='"content" "toolbar"'
      gridTemplateRows='1fr 0px'
      gap='0'
      height='full'
      overflowY='auto'
      maxHeight='full'
    >
      {!codeMode ? (
        <ConfigCard.Wrapper
          gridArea='content'
          overflowY='auto'
          paddingBlockEnd='16'
          {...props}
        >
          {children}
        </ConfigCard.Wrapper>
      ) : (
        <ConfigCard.Wrapper
          gridArea='content'
          paddingBlockEnd='16'
          paddingBlockStart='4!important'
          display='grid'
          placeContent='stretch'
          gridTemplateColumns='1fr'
          gridTemplateRows='1fr'
          {...props}
        >
          <CodeEditor
            code={code}
            onCodeChange={onCodeChange}
            readOnly={readOnlyCode}
          />
        </ConfigCard.Wrapper>
      )}
      {ToolbarComponent && (
        <Box gridArea='toolbar' position='relative' className='dark'>
          <Grid
            position='absolute'
            bottom='0'
            left='0'
            right='0'
            placeContent='center'
            pointerEvents='none'
          >
            <ToolbarComponent
              variant='floated'
              borderBottomRadius='0'
              codeMode={codeMode}
              onToggle={toggleCodeMode}
            />
          </Grid>
        </Box>
      )}
    </Grid>
  )
}

export function ConfigCardWrapperWithCodeMode({
  children,
  onCodeChange,
  code,
  codeMode,
  ...props
}: BoxProps & {
  code: any
  onCodeChange(data: string): void
  codeMode: boolean
}) {
  // magic fix to avoid constant re-rendering of the editor
  // when scrollbar is always visible (like on windows)

  if (codeMode) {
    return (
      <ConfigCard.Wrapper
        paddingBlockStart='4!important'
        display='grid'
        placeContent='stretch'
        gridTemplateColumns='1fr'
        gridTemplateRows='1fr'
        {...props}
      >
        <CodeEditor code={code} onCodeChange={onCodeChange} />
      </ConfigCard.Wrapper>
    )
  }

  return <ConfigCard.Wrapper {...props}>{children}</ConfigCard.Wrapper>
}

function CodeEditor({
  onCodeChange,
  code,
  readOnly,
  ...props
}: {
  code: any
  onCodeChange(data: string): void
  readOnly?: boolean
} & BoxProps) {
  // magic fix to avoid constant re-rendering of the editor
  // when scrollbar is always visible (like on windows)
  return (
    <Box
      minHeight='full'
      maxWidth='full'
      borderRadius='l3'
      borderWidth='1'
      borderColor='border.subtle'
      padding='2'
      backgroundColor='bg.default'
      gridArea='1 / 1'
      {...props}
    >
      <YAMLEditor value={code} onChange={onCodeChange} readOnly={readOnly} />
    </Box>
  )
}
