import { PropsWithChildren, ReactNode, useEffect, useState } from 'react'
import {
  useAppDataSchema,
  FieldDescription,
  SvgIcon,
  SvgIconType,
  SimpleInput,
  getLocatorsFromSchema,
} from '@integration-app/react'
import { FieldMappingDirection } from '@integration-app/sdk'
import { SchemaBuilder } from '@integration-app/ui'
import {
  TbInfoCircle,
  TbDotsVertical,
  TbChevronUp,
  TbChevronDown,
  TbArrowRightCircle,
  TbArrowLeftCircle,
  TbBook,
  TbTrash,
  TbArrowBackUp,
} from 'react-icons/tb'
import { NavLink, useNavigate } from 'react-router-dom'
import useSWR from 'swr'

import { Alert } from 'components/Alert'
import AppDataSchemaSelect from 'components/AppDataSchemas/AppDataSchemaSelect'
import { IconButton } from 'components/Button/IconButton'
import { ConfigCard } from 'components/Card/ConfigCard'
import { ContentRows } from 'components/ContentRows'
import DataSourceSelect from 'components/DataSources/DataSourceSelect'
import DocLink from 'components/Docs/DocLink'
import { Menu } from 'components/Menu'
import { RadioGroup } from 'components/RadioGroup'
import { SchemaConfigCard } from 'components/SchemaConfigCard'
import SimpleSelect from 'components/SimpleSelect'
import { CodeModeSwitch } from 'components/Switch/CodeModeSwitch'
import { Toolbar } from 'components/Toolbar'
import { useWorkspace } from 'components/Workspaces/workspace-context'
import { routeTo } from 'routes/Workspaces/Workspace/routes-constants'
import { Icon } from 'ui-kit/icon'
import { Text } from 'ui-kit/text'
import { withErrorToast } from 'utils/withErrorToast'

import { ExportFieldMappingConfig } from './ExportFieldMappingConfig'
import {
  FrozenExportFieldsConfig,
  FrozenImportFieldsConfig,
} from './FrozenFieldsConfig'
import { ImportFieldMappingConfig } from './ImportFieldMappingConfig'
import RelatedAppDataSchemaSection from '../../../../components/ConfigPaneSection/RelatedAppDataSchemaSection'
import RelatedDataSourceSection from '../../../../components/ConfigPaneSection/RelatedDataSourceSection'
import RelatedFieldMappingSection from '../../../../components/ConfigPaneSection/RelatedFieldMappingSection'
import RelatedIntegrationSection from '../../../../components/ConfigPaneSection/RelatedIntegrationSection'
import {
  ConfigCardWrapperWithToolbarCodeModeSwitch,
  ToolbarComponentProps,
} from '../../../../components/ElementPageWrapperWithCodeMode'
import { useFieldMappingContext } from '../contexts/field-mapping-context'

enum UniversalInternalSchemaOption {
  Auto = 'auto',
  Shared = 'shared',
  Custom = 'custom',
}

enum ChildInternalSchemaOption {
  Parent = 'parent',
  Custom = 'custom',
}

export const FIELD_MAPPING_DIRECTION_OPTIONS = [
  {
    value: FieldMappingDirection.IMPORT,
    label: 'Import (from external app to yours)',
  },
  {
    value: FieldMappingDirection.EXPORT,
    label: 'Export (from your app to external app)',
  },
  {
    value: FieldMappingDirection.BOTH,
    label: 'Bi-directional',
  },
]

export function FieldMappingConfiguration() {
  const { fieldMapping, onCodeChange, code } = useFieldMappingContext()

  return (
    <ConfigCardWrapperWithToolbarCodeModeSwitch
      key={fieldMapping.id} // to force rerender for code/ui switch
      code={code}
      onCodeChange={onCodeChange}
      minHeight='full'
      toolbar={FieldMappingToolbar}
    >
      <Config />
    </ConfigCardWrapperWithToolbarCodeModeSwitch>
  )
}

function Config() {
  const { fieldMapping, onChange, parent, dataSource } =
    useFieldMappingContext()

  // @ts-ignore
  const { appDataSchema } = useAppDataSchema({
    key: fieldMapping?.appSchema?.['$ref'],
  })

  const isChild = !!fieldMapping.universalFieldMappingId

  return (
    <>
      <ConfigCard.Root>
        <ConfigCard.Header
          leftSlot={
            <Icon>
              <TbInfoCircle />
            </Icon>
          }
        >
          <ConfigCard.Title>Fields</ConfigCard.Title>
        </ConfigCard.Header>

        <ConfigCard.Content>
          <ContentRows>
            <SimpleInput
              label='Name'
              value={fieldMapping.name}
              onChange={(name) => onChange({ name })}
            />

            <SimpleInput
              label='Key'
              value={fieldMapping.key}
              disabled={!!fieldMapping.universalFieldMappingId}
              onChange={(key) => onChange({ key })}
            />

            <SimpleSelect
              name='Direction'
              description={
                'Does it map fields from your app to external app or the other way around.'
              }
              required={true}
              disabled={!!fieldMapping.universalFieldMappingId}
              value={fieldMapping.direction}
              options={FIELD_MAPPING_DIRECTION_OPTIONS}
              onChange={(direction: FieldMappingDirection) =>
                onChange({ direction })
              }
            />
          </ContentRows>

          {isChild && (
            <Alert.Root marginBlockStart={3} icon={<TbInfoCircle />}>
              <Alert.Description>
                <Text m={0}>
                  This field mapping is based on a{' '}
                  <NavLink
                    className='link'
                    to={routeTo.fieldMapping(
                      fieldMapping.universalFieldMappingId!,
                    )}
                  >
                    universal field mapping
                  </NavLink>
                  .
                </Text>
                <Text m={0}>
                  You can edit direction, external, and internal fields schema
                  there.
                </Text>
              </Alert.Description>
            </Alert.Root>
          )}
        </ConfigCard.Content>
      </ConfigCard.Root>

      {fieldMapping.direction && (
        <>
          <ConfigCard.Root>
            <ConfigCard.Header>
              <ConfigCard.Title>Fields</ConfigCard.Title>
            </ConfigCard.Header>
          </ConfigCard.Root>

          <ConfigCard.NestedWrapper>
            <ExternalFieldsConfig />

            {isChild ? (
              <ChildInternalFieldsConfig />
            ) : (
              <UniversalInternalFieldsConfig />
            )}
          </ConfigCard.NestedWrapper>

          <ConfigCard.Root>
            <ConfigCard.Header>
              <ConfigCard.Title>Mapping</ConfigCard.Title>
            </ConfigCard.Header>
          </ConfigCard.Root>

          <ConfigCard.NestedWrapper>
            {(fieldMapping.direction === FieldMappingDirection.BOTH ||
              fieldMapping.direction === FieldMappingDirection.EXPORT) && (
              <>
                <ExportFieldMappingConfig />
                <ConfigCard.NestedWrapper>
                  <FrozenExportFieldsConfig />
                </ConfigCard.NestedWrapper>
              </>
            )}
            {(fieldMapping.direction === FieldMappingDirection.BOTH ||
              fieldMapping.direction === FieldMappingDirection.IMPORT) && (
              <>
                <ImportFieldMappingConfig />
                <ConfigCard.NestedWrapper>
                  <FrozenImportFieldsConfig />
                </ConfigCard.NestedWrapper>
              </>
            )}
          </ConfigCard.NestedWrapper>
        </>
      )}

      <ConfigCard.Root>
        <ConfigCard.Header>
          <ConfigCard.Title>Related Elements</ConfigCard.Title>
        </ConfigCard.Header>
      </ConfigCard.Root>

      <ConfigCard.NestedWrapper>
        <RelatedFieldMappingSection fieldMapping={parent} />
        <RelatedIntegrationSection integration={fieldMapping.integration} />
        <RelatedDataSourceSection dataSource={dataSource} />
        <RelatedAppDataSchemaSection appDataSchema={appDataSchema} />
      </ConfigCard.NestedWrapper>
    </>
  )
}

function FieldMappingToolbar({
  codeMode,
  onToggle,
  ...props
}: ToolbarComponentProps) {
  return (
    <Toolbar.Root {...props}>
      <Toolbar.Group>
        <CodeModeSwitch codeMode={codeMode} onToggle={onToggle} />
      </Toolbar.Group>

      <Toolbar.Group>
        <DocLink
          colorPalette='accent'
          path='membrane/building-blocks/field-mappings'
          leftIcon={<TbBook />}
        >
          Docs
        </DocLink>

        <FieldMappingSettingsMenu />
      </Toolbar.Group>
    </Toolbar.Root>
  )
}

function FieldMappingSettingsMenu() {
  const navigate = useNavigate()
  const { fieldMapping, reset, archive, parent } = useFieldMappingContext()

  const [isReady, setIsReady] = useState(true)

  async function handleArchive() {
    setIsReady(false)
    await withErrorToast(async () => {
      await archive()
      navigate(parent ? routeTo.fieldMapping(parent) : routeTo.fieldMappings())
    })
    setIsReady(true)
  }

  async function handleReset() {
    setIsReady(false)
    await withErrorToast(reset)
    setIsReady(true)
  }

  return (
    <Menu.Root>
      <Menu.Trigger>
        <IconButton
          size={'dense'}
          variant={'ghost'}
          tooltip={'More Actions...'}
          loading={!isReady}
          disabled={!isReady}
        >
          <TbDotsVertical />
        </IconButton>
      </Menu.Trigger>
      <Menu.Content usePortal>
        <Menu.Item onClick={handleArchive} value='archive' icon={<TbTrash />}>
          Archive
        </Menu.Item>

        {fieldMapping.universalFieldMappingId && fieldMapping.customized && (
          <Menu.Item
            onClick={handleReset}
            value='reset'
            icon={<TbArrowBackUp />}
          >
            Reset
          </Menu.Item>
        )}
      </Menu.Content>
    </Menu.Root>
  )
}

function ExternalFieldsConfig() {
  const { fieldMapping, onChange, dataCollectionSpec } =
    useFieldMappingContext()

  return (
    <>
      <ConfigCard.Root>
        <ConfigCard.Header
          leftSlot={
            <Icon>
              <TbArrowRightCircle />
            </Icon>
          }
        >
          <ConfigCard.Title>External Fields</ConfigCard.Title>
          <FieldDescription text={'Fields used in the external app'} />
        </ConfigCard.Header>
        <ConfigCard.Content>
          <DataSourceSelect
            integrationId={fieldMapping.integrationId}
            dataSourceKey={fieldMapping.dataSourceKey}
            disabled={!!fieldMapping.universalFieldMappingId}
            onChange={(dataSourceKey) => onChange({ dataSourceKey })}
          />
        </ConfigCard.Content>
      </ConfigCard.Root>

      <ConfigCard.NestedWrapper>
        {dataCollectionSpec && (
          <CollapsableConfigCard title='Schema' collapsed={true}>
            <SchemaBuilder readOnly schema={dataCollectionSpec?.fieldsSchema} />
          </CollapsableConfigCard>
        )}
      </ConfigCard.NestedWrapper>
    </>
  )
}

function UniversalInternalFieldsConfig() {
  const { engineAdminFetcher } = useWorkspace()
  const { fieldMapping, onChange } = useFieldMappingContext()
  const [selectedOption, setSelectedOption] = useState(
    UniversalInternalSchemaOption.Auto,
  )

  const { data: internalSchema, mutate } = useSWR(
    fieldMapping ? `field-mappings/${fieldMapping.id}/app-schema` : null,
    engineAdminFetcher,
  )

  async function onSchemaChange(newSchema) {
    await onChange({ appSchema: newSchema })
    await mutate(newSchema)
  }

  useEffect(() => {
    if (fieldMapping.appSchema?.$ref !== undefined) {
      setSelectedOption(UniversalInternalSchemaOption.Shared)
    } else if (fieldMapping.appSchema) {
      setSelectedOption(UniversalInternalSchemaOption.Custom)
    } else {
      setSelectedOption(UniversalInternalSchemaOption.Auto)
    }
  }, [fieldMapping?.id])

  function handleSelectOption({
    value,
  }: {
    value: UniversalInternalSchemaOption
  }) {
    setSelectedOption(value)
    switch (value) {
      case UniversalInternalSchemaOption.Auto:
        return onSchemaChange(undefined)
      case UniversalInternalSchemaOption.Shared:
        return onSchemaChange({
          $ref: null,
        })
      case UniversalInternalSchemaOption.Custom:
        return onSchemaChange({
          type: 'object',
        })
    }
  }

  return (
    <>
      <ConfigCard.Root>
        <ConfigCard.Header
          leftSlot={
            <Icon>
              <TbArrowLeftCircle />
            </Icon>
          }
        >
          <ConfigCard.Title>
            Internal Fields: Fields used in your app
          </ConfigCard.Title>
        </ConfigCard.Header>
        <ConfigCard.Content>
          <RadioGroup.Root
            mb={4}
            gap={1}
            value={selectedOption}
            onValueChange={handleSelectOption}
          >
            <RadioGroup.Item value={UniversalInternalSchemaOption.Auto}>
              Auto-detect from mapping
            </RadioGroup.Item>
            <RadioGroup.Item value={UniversalInternalSchemaOption.Custom}>
              Custom schema
            </RadioGroup.Item>
            <RadioGroup.Item value={UniversalInternalSchemaOption.Shared}>
              Shared App Data Schema
            </RadioGroup.Item>
          </RadioGroup.Root>

          {selectedOption === UniversalInternalSchemaOption.Shared && (
            <AppDataSchemaSelect
              value={fieldMapping.appSchema?.$ref}
              onChange={(key) =>
                onChange({
                  appSchema: {
                    $ref: key,
                  },
                })
              }
            />
          )}
        </ConfigCard.Content>
      </ConfigCard.Root>

      <ConfigCard.NestedWrapper>
        {selectedOption === UniversalInternalSchemaOption.Custom && (
          <SchemaConfigCard
            title='Custom Schema'
            schema={fieldMapping.appSchema}
            onChange={onSchemaChange}
          />
        )}

        {selectedOption === UniversalInternalSchemaOption.Shared && (
          <CollapsableConfigCard title='Schema' collapsed={true}>
            {fieldMapping.appSchema?.$ref ? (
              <SchemaBuilder readOnly schema={internalSchema} />
            ) : (
              <Alert.Root icon={<TbInfoCircle />}>
                <Alert.Description>
                  Please select an app data schema.
                </Alert.Description>
              </Alert.Root>
            )}
          </CollapsableConfigCard>
        )}

        {selectedOption === UniversalInternalSchemaOption.Auto && (
          <CollapsableConfigCard title='Schema' collapsed={true}>
            {getLocatorsFromSchema(internalSchema).length > 0 ? (
              <SchemaBuilder readOnly schema={internalSchema} />
            ) : (
              <Alert.Root icon={<TbInfoCircle />}>
                <Alert.Description>
                  No schema auto-detected from the current mapping. Please
                  either configure the mapping or choose a custom/shared app
                  data schema.
                </Alert.Description>
              </Alert.Root>
            )}
          </CollapsableConfigCard>
        )}
      </ConfigCard.NestedWrapper>
    </>
  )
}

function ChildInternalFieldsConfig() {
  const { engineAdminFetcher } = useWorkspace()
  const { fieldMapping, onChange } = useFieldMappingContext()
  const [selectedOption, setSelectedOption] = useState(
    ChildInternalSchemaOption.Parent,
  )

  const { data: internalSchema, mutate } = useSWR(
    fieldMapping ? `field-mappings/${fieldMapping.id}/app-schema` : null,
    engineAdminFetcher,
  )

  async function onSchemaChange(newSchema) {
    await onChange({ appSchema: newSchema })
    await mutate(newSchema)
  }

  useEffect(() => {
    if (fieldMapping.appSchema) {
      setSelectedOption(ChildInternalSchemaOption.Custom)
    }
  }, [fieldMapping?.id])

  function handleSelectOption({ value }: { value: ChildInternalSchemaOption }) {
    setSelectedOption(value)
    switch (value) {
      case ChildInternalSchemaOption.Parent:
        return onSchemaChange(undefined)
      case ChildInternalSchemaOption.Custom:
        return onSchemaChange({ type: 'object' })
    }
  }

  return (
    <>
      <ConfigCard.Root>
        <ConfigCard.Header leftSlot={<SvgIcon type={SvgIconType.SyncLeft} />}>
          <ConfigCard.Title>
            Internal Fields: Fields used in your app
          </ConfigCard.Title>
        </ConfigCard.Header>
        <ConfigCard.Content>
          <RadioGroup.Root
            mb={4}
            gap={1}
            value={selectedOption}
            onValueChange={handleSelectOption}
          >
            <RadioGroup.Item value={ChildInternalSchemaOption.Parent}>
              Same as universal field mapping
            </RadioGroup.Item>
            <RadioGroup.Item value={ChildInternalSchemaOption.Custom}>
              Same as universal field mapping + custom fields
            </RadioGroup.Item>
          </RadioGroup.Root>
        </ConfigCard.Content>
      </ConfigCard.Root>

      <ConfigCard.NestedWrapper>
        <CollapsableConfigCard collapsed={true} title={'Schema'}>
          {getLocatorsFromSchema(internalSchema).length > 0 ? (
            <SchemaBuilder readOnly schema={internalSchema} />
          ) : (
            <Alert.Root icon={<TbInfoCircle />}>
              <Alert.Description>
                Universal field mapping has no schema. Please configure{' '}
                <NavLink
                  className='link'
                  to={routeTo.fieldMapping(
                    fieldMapping.universalFieldMappingId!,
                  )}
                >
                  universal field mapping
                </NavLink>{' '}
                or configure custom schema.
              </Alert.Description>
            </Alert.Root>
          )}
        </CollapsableConfigCard>

        {selectedOption === ChildInternalSchemaOption.Custom && (
          <SchemaConfigCard
            title='Custom Schema'
            schema={fieldMapping.appSchema}
            onChange={onSchemaChange}
          />
        )}
      </ConfigCard.NestedWrapper>
    </>
  )
}

export function CollapsableConfigCard({
  children,
  title,
  description,
  collapsed = false,
  leftSlot,
}: PropsWithChildren<{
  title: string
  description?: string
  collapsed?: boolean
  leftSlot?: ReactNode
}>) {
  const [isOpen, setIsOpen] = useState(!collapsed)

  return (
    <ConfigCard.Root>
      <ConfigCard.Header
        leftSlot={leftSlot}
        menuSlot={
          <IconButton
            variant={'ghost'}
            size={'sm'}
            tooltip={'Expand Output Schema'}
            onClick={() => setIsOpen(!isOpen)}
          >
            <Icon>{isOpen ? <TbChevronUp /> : <TbChevronDown />}</Icon>
          </IconButton>
        }
      >
        <ConfigCard.Title>{title}</ConfigCard.Title>
        {description && <FieldDescription text={description} />}
      </ConfigCard.Header>

      {isOpen && <ConfigCard.Content>{children}</ConfigCard.Content>}
    </ConfigCard.Root>
  )
}
