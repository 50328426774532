import { PropsWithChildren } from 'react'

import { Box } from 'styled-system/jsx'
import clsx from 'utils/clsx'

import classes from './styles.module.css'

export function DocFullPageHeader({ children }: PropsWithChildren) {
  return (
    <Box
      backgroundColor='bg.canvas'
      position='sticky'
      top='0'
      zIndex='docked'
      borderBlockEndWidth='1'
      borderBlockEndColor='border.muted'
      borderBlockEndStyle='solid'
    >
      <Box className={clsx(classes.container, classes.siteHeader_content)}>
        {children}
      </Box>
    </Box>
  )
}
