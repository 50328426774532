import { ComponentProps, PropsWithChildren, ReactNode } from 'react'
import { TbChevronRight } from 'react-icons/tb'

import { sva } from 'styled-system/css'
import { Box, BoxProps, Grid, Square, SquareProps } from 'styled-system/jsx'
import { Button, ButtonProps } from 'ui-kit/button'
import { Heading } from 'ui-kit/heading'
import { Icon, IconProps } from 'ui-kit/icon'
import { Text } from 'ui-kit/text'

const itemButtonRecipe = sva({
  className: 'itemButton',
  slots: ['root', 'header', 'description', 'icon', 'chevron', 'slotWrapper'],
  base: {
    root: {
      lineHeight: 'tight',
      minHeight: '9',
      height: 'auto',
      paddingInline: '2',
      paddingBlock: '1.5',
      rounded: 'l3',
      textAlign: 'left',
      flexShrink: 0,
      justifyContent: 'start',
      '&:has(> :first-child:not(.itemButton__slotWrapper))': {
        paddingInlineStart: '3',
      },
    },
    header: {
      margin: 0,
      textStyle: 'md',
      fontWeight: 'normal',
      lineHeight: 'tight',
      truncate: true,
    },
    description: {
      margin: 0,
      textStyle: 'sm',
      lineHeight: 'tight',
      truncate: true,
      color: 'fg.muted',
    },
    icon: {
      backgroundColor: 'bg.muted',
      borderWidth: '1',
      borderColor: 'border.muted',
      color: 'fg.subtle',
      rounded: 'l2',
      '& svg': {
        strokeWidth: '1.5',
        width: '4',
        height: '4',
      },
    },
    chevron: {
      width: '3',
      height: '3',
    },
    slotWrapper: {
      display: 'contents',
      _empty: {
        display: 'none',
      },
    },
  },
})

const itemButtonClassnames = itemButtonRecipe()

function ItemButtonRoot({
  children,
  leftSlot,
  rightSlot,
  ...props
}: ButtonProps & {
  leftSlot?: ReactNode
  rightSlot?: ReactNode
}) {
  return (
    <Button variant='ghost' className={itemButtonClassnames.root} {...props}>
      <SlotWrapper>{leftSlot}</SlotWrapper>
      <Grid
        gridTemplateColumns='1fr'
        gap='0'
        alignItems='start'
        justifyContent='start'
        flexGrow='1'
        flexShrink='1'
      >
        {children}
      </Grid>
      <SlotWrapper>{rightSlot}</SlotWrapper>
    </Button>
  )
}

function Header({ children, ...props }: ComponentProps<typeof Heading>) {
  return (
    <Heading as={'h4'} className={itemButtonClassnames.header} {...props}>
      {children}
    </Heading>
  )
}

function Description({ children, ...props }: ComponentProps<typeof Text>) {
  return (
    <Text className={itemButtonClassnames.description} {...props}>
      {children}
    </Text>
  )
}

function IconWrapper({ children, ...props }: PropsWithChildren & SquareProps) {
  return (
    <Square size={6} className={itemButtonClassnames.icon} {...props}>
      <Icon width='4' height='4'>
        {children}
      </Icon>
    </Square>
  )
}

function Chevron(props: IconProps) {
  return (
    <Icon className={itemButtonClassnames.chevron} {...props}>
      <TbChevronRight />
    </Icon>
  )
}

function SlotWrapper({ children, ...props }: BoxProps) {
  if (!children) {
    return null
  }
  return (
    <Box className={itemButtonClassnames.slotWrapper} {...props}>
      {children}
    </Box>
  )
}

const ItemButton = {
  Root: ItemButtonRoot,
  Header: Header,
  Description: Description,
  Icon: IconWrapper,
  Chevron,
}

export { ItemButton }
