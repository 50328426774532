import { useState, useEffect } from 'react'
import Fuse from 'fuse.js'
import { useDebounce } from 'use-debounce'
import { useDebounceCallback } from 'usehooks-ts'

import { HStack } from 'styled-system/jsx'
import { Checkbox } from 'ui-kit/checkbox'
import { Input, InputProps } from 'ui-kit/input'

type SearchInputProps = Omit<InputProps, 'onChange'> & {
  onChange?: (value: string) => void
}

export function SearchInput({ onChange, ...props }: SearchInputProps) {
  const handleChange = (e) => onChange?.(e?.target?.value ?? '')

  return <Input searchIcon onChange={handleChange} {...props} />
}

export function SearchInputIncludeArchived({
  includeArchived,
  onIncludeArchivedChange,
  ...props
}: SearchInputProps & {
  includeArchived?: boolean
  onIncludeArchivedChange: (includeArchived: boolean) => void
}) {
  return (
    <HStack gap='4' flexGrow='1'>
      <SearchInput {...props}></SearchInput>
      <Checkbox
        checked={includeArchived}
        onCheckedChange={() => onIncludeArchivedChange(!includeArchived)}
      >
        include archived
      </Checkbox>
    </HStack>
  )
}

export function DebouncedSearchInput({
  debounce = 300,
  value,
  onChange,
  ...props
}: { debounce?: number } & SearchInputProps) {
  const [localValue, setLocalValue] = useState<string>(value?.toString() ?? '')

  useEffect(() => {
    setLocalValue(value?.toString() ?? '')
  }, [value])

  function safeOnChange(newValue: string) {
    onChange?.(newValue)
  }

  const debouncedOnChange = useDebounceCallback(safeOnChange, debounce)

  function handleOnChange(newValue = '') {
    setLocalValue(newValue)
    debouncedOnChange(newValue)
  }

  return <SearchInput onChange={handleOnChange} value={localValue} {...props} />
}

export function filterItemsBySearch<T>(
  items: T[],
  searchValue: string,
  keys: string[],
  threshold = 0.6,
): T[] {
  if (!searchValue) return items

  return new Fuse(items, {
    shouldSort: true,
    threshold,
    keys,
  })
    .search(searchValue.trim())
    .map(({ item }) => item)
}

export function useSearch<Item>(
  items: Item[],
  searchByFields: string[],
  threshold?: number,
) {
  const [search, setSearch] = useState('')

  const filtered = filterItemsBySearch(items, search, searchByFields, threshold)

  return {
    filtered,
    search,
    setSearch,
  }
}

export function useDebouncedSearch(delay = 300) {
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebounce(search, delay)

  return {
    search,
    debouncedSearch,
    setSearch,
  }
}
