import { sva } from 'styled-system/css'
import { Box, HStack, Square, SquareProps } from 'styled-system/jsx'
import * as StyledSwitch from 'ui-kit/styled/switch'

import { SwitchControl } from '.'

const codeModeSwitchRecipe = sva({
  className: 'codeModeSwitch',
  slots: ['labelLeft', 'labelRight', 'control', 'thumb'],
  base: {
    control: {
      width: '3.1rem',
      position: 'relative',
      _checked: {
        '& .codeModeSwitch__labelLeft': {
          opacity: 0.8,
        },
        '& .codeModeSwitch__labelRight': {
          opacity: 1,
        },
      },
    },
    labelLeft: {
      color: 'fg.default',
      opacity: 1,
    },
    labelRight: {
      color: 'fg.default',
      opacity: 0.8,
    },
    thumb: {
      _checked: {
        width: '7',
        transform: 'translateX(calc({sizes.4} + {sizes.0.5}))',
      },
    },
  },
})

interface CodeModeSwitchProps {
  codeMode: boolean
  onToggle: (checked: boolean) => void
}

export function CodeModeSwitch({
  codeMode,
  onToggle,
  ...rootProps
}: CodeModeSwitchProps) {
  const classes = codeModeSwitchRecipe()
  return (
    <StyledSwitch.Root
      staticBackground
      checked={codeMode}
      onCheckedChange={({ checked }) => onToggle(checked)}
      width='fit-content'
      {...rootProps}
    >
      <SwitchControl className={classes.control} tooltip='Toggle Code Mode'>
        <StyledSwitch.Thumb className={classes.thumb} />
        <HStack
          position='absolute'
          inset='0'
          gap='0'
          paddingInline='0.5'
          alignItems='center'
          justifyContent='space-between'
          pointerEvents='none'
          aria-hidden={true}
        >
          <TextLabel width='4' className={classes.labelLeft}>
            UI
          </TextLabel>
          <TextLabel width='7' className={classes.labelRight}>
            Code
          </TextLabel>
        </HStack>
      </SwitchControl>
      <StyledSwitch.HiddenInput />
    </StyledSwitch.Root>
  )
}

// Browsers have a minimum font size of 8-10-12px for accessibility reasons.
// We need to scale the text down to 6px to fit the switch.
function TextLabel({ children, ...props }: SquareProps) {
  return (
    <Square
      height='4'
      flexShrink='0'
      fontSize='2xl'
      fontWeight='medium'
      lineHeight='none'
      {...props}
    >
      <Box transform='scale(0.48)'>{children}</Box>
    </Square>
  )
}
