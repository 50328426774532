import { SvgIcon, SvgIconType } from '@integration-app/react'
import { NavLink } from 'react-router-dom'

import { useDocMenu } from 'routes/Docs/components/DocsMenu/useDocMenu'
import {
  MenuItemType,
  MenuItemLinkType,
  isMenuItemLinkType,
} from 'routes/Docs/types'
import { sva, cx } from 'styled-system/css'

export function FirstLevelDocsMenu() {
  const docMenu = useDocMenu()
  const firstLevelMenu: MenuItemLinkType[] = docMenu.filter((link) =>
    isMenuItemLinkType(link),
  )
  return <Menu items={firstLevelMenu} />
}

const menuRecipe = sva({
  slots: ['nav', 'ul', 'li', 'a', 'a__active', 'a__external'],
  base: {
    nav: {
      gridArea: 'menu',
    },
    ul: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '0.5',
      listStyle: 'none',
      marginInlineStart: '-2',
    },
    li: {
      color: 'fg.muted',
    },
    a: {
      borderRadius: 'l2',
      paddingInline: '2',
      paddingBlock: '1',
      _hover: {
        textDecoration: 'underline',
        backgroundColor: 'bg.subtle',
      },
    },
    a__active: {
      color: 'fg.default',
    },
    a__external: {},
  },
})
const menuRecipeClassnames = menuRecipe()

function Menu({ items }: { items?: MenuItemLinkType[] }) {
  if (!items?.length) {
    return null
  }
  return (
    <nav className={menuRecipeClassnames.nav}>
      <ul className={menuRecipeClassnames.ul}>
        {items.map((item, i) => (
          <MenuItem item={item} key={i} />
        ))}
      </ul>
    </nav>
  )
}

function MenuItem({ item }: { item: MenuItemLinkType }) {
  return (
    <li className={menuRecipeClassnames.li}>
      <MenuItemLink item={item as MenuItemLinkType} />
    </li>
  )
}

function MenuItemLink({ item }: { item: MenuItemLinkType }) {
  if (item.isExternalLink) {
    return (
      <a
        className={cx(menuRecipeClassnames.a, menuRecipeClassnames.a__external)}
        href={item.to}
        rel='noreferrer'
      >
        {item.title} <SvgIcon type={SvgIconType.ExternalLink} />
      </a>
    )
  }
  return (
    <NavLink
      className={cx(
        menuRecipeClassnames.a,
        itemIsActive(item) && menuRecipeClassnames.a__active,
      )}
      to={item.to}
    >
      {item.title}
    </NavLink>
  )
}

function itemIsActive(item: MenuItemType) {
  return (
    item.isActive ||
    item.links?.some((child) => child.isActive || itemIsActive(child))
  )
}
