import { SearchInput } from 'components/SearchInput'

import classes from './AlgoliaSearch.module.css'

export function AlgoliaSidebarSearchPlaceholder({ onClick }) {
  return (
    <div
      className={classes.sidebarSearch}
      onMouseDown={(event) => {
        event.preventDefault()
        onClick()
      }}
    >
      <SearchInput
        width='64'
        height='10'
        fontSize='sm'
        backgroundColor='bg.surface'
        placeholder='Type to search'
      />
      <span className={classes.sidebarSearch_shortcuts}>
        <kbd className={'visible-on-mac'}>
          <MacIcon />
        </kbd>
        <kbd className={'visible-not-on-mac'}>Ctrl</kbd>+<kbd>K</kbd>
      </span>
    </div>
  )
}

function MacIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox={'0 0 24 24'}
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d='M7 9a2 2 0 1 1 2-2v10a2 2 0 1 1-2-2h10a2 2 0 1 1-2 2V7a2 2 0 1 1 2 2H7' />
    </svg>
  )
}
